<template>
  <el-form-item>
    <div class="container">
      <div class="flex items-center justify-between" style="margin-bottom: 8px">
        <ElInputWrapper>
          <el-input v-model="syncData.title" style="width: 240px" placeholder="請輸入標題（未填則不顯示標題）" />
        </ElInputWrapper>
        <div class="flex items-center" style="gap: 20px">
          <div>
            <el-button class="move-layer-btn" type="text" @click="$emit('upLayer')">
              上移一層<i class="el-icon-top el-icon--right" />
            </el-button>
            <el-button class="move-layer-btn" type="text" @click="$emit('downLayer')">
              下移一層<i class="el-icon-bottom el-icon--right" />
            </el-button>
          </div>
          <el-button class="remove-btn" type="text" @click="$emit('remove')">
            刪除<i class="el-icon-delete-solid el-icon--right" />
          </el-button>
        </div>
      </div>
      <el-input
        v-model="syncData.content"
        type="textarea"
        show-word-limit
        maxlength="150"
        :autosize="{ minRows: 5, maxRows: 6}"
        placeholder="請輸入"
      />
    </div>
  </el-form-item>
</template>

<script>
import { defineComponent, ref } from 'vue'
import ElInputWrapper from './ElInputWrapper.vue'
import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'ContentBlockEditBlock',
  components: {
    ElInputWrapper,
  },
  props: {
    data: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const syncData = useVModel(props, 'data', emit)
    return { syncData }
  },
})
</script>

<style lang="postcss" scoped>
.container {
  @apply bg-gray-20 rounded-[8px] px-[12px] py-[20px] max-w-[560px];
}

.move-layer-btn {
    @apply border-solid border-[1px] border-primary-100 py-[5px] px-[8px] text-sm;
  }

  .remove-btn {
    @apply border-solid border-[1px] border-danger py-[5px] px-[8px] text-sm text-danger;
  }
  ::v-deep .el-textarea {
    @apply w-full;
  }

::v-deep .el-textarea .el-input__count {
  @apply right-[10px] bottom-0;
}
</style>
