<template>
  <div class="content-setting">
    <PageTitle
      title="編輯自定義區塊"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({ name: 'PubAptContentSetting' })"
    />
    <section v-loading="loading" class="section-block card-container">
      <p class="title">文字訊息設定</p>
      <el-form ref="formRef" label-position="top" :model="formData">
        <el-form-item prop="fullContentSetting">
          <div class="">
            <div class="content-block-list">
              <div v-for="(block, idx) in contentList" :key="`${block.type}-${idx}`">
                <TextBlockEditBlock
                  v-if="block.type === 'text'"
                  :data.sync="contentList[idx]"
                  @remove="removeBlock(idx)"
                  @upLayer="moveData(idx, 'up')"
                  @downLayer="moveData(idx, 'down')"
                />
              </div>
            </div>
            <div class="flex items-center">
              <AddButton v-if="contentList.length < 5" content="新增文字" @click="addBlock('text')" />
            </div>
          </div>
        </el-form-item>
      </el-form>
    </section>

    <PageFixedFooter @cancel="$router.push({ name: 'PubAptContentSetting' })" @confirm="submitCheck" />
  </div>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle.vue'
import { defineComponent, onMounted, watch, ref } from 'vue'
import TextBlockEditBlock from '@/components/TextBlockEditBlock.vue'
import AddButton from '@/components/Button/AddButton.vue'
import { useImageTextContent } from '@/use/useImageTextContent'
import { FindPubAptConfig, UpdatePubAptConfig } from '@/api/pubApt'
import { useShop } from '@/use/shop'
import { useRouter } from 'vue-router/composables'
import { map, some } from 'lodash'
import { useBaseForm } from '@/use/useForm'

export default defineComponent({
  name: 'EditCustomSetting',
  components: {
    PageTitle,
    TextBlockEditBlock,
    AddButton,
  },
  setup (props, { emit }) {
    const router = useRouter()
    const { shopId } = useShop()
    const loading = ref(false)
    const { contentList, addBlock, removeBlock, moveData } = useImageTextContent()
    const { formRef, formData, initFormData, checkForm } = useBaseForm()
    initFormData({
      fullContentSetting: [],
    })
    watch(contentList, () => {
      formData.fullContentSetting = contentList.value
    }, { deep: true })

    const findPubAptConfig = async () => {
      loading.value = true
      const [res, err] = await FindPubAptConfig({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      formData.fullContentSetting = map(res.fullContentSetting || [], item => ({
        ...item,
        content: item.text,
        text: undefined,
      }))
      contentList.value = formData.fullContentSetting
      loading.value = false
    }
    const checkTextBlockContent = () => {
      return some(formData.fullContentSetting, (item) => {
        if (item.type === 'text') {
          if (!item.content) {
            window.$message.warning('請填寫內容')
            return true
          }
        }
        return false
      })
    }
    const submitCheck = async () => {
      const isInValid = checkTextBlockContent()
      if (isInValid) {
        return
      }
      const [, err] = await UpdatePubAptConfig({
        shopId: shopId.value,
        data: {
          fullContentSetting: map(contentList.value, item => ({
            ...item,
            text: item.content,
            content: undefined,
          })),
        },
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功!')
      router.push({ name: 'PubAptContentSetting' })
    }

    onMounted(async () => {
      await findPubAptConfig()
    })

    return { formData, formRef, contentList, addBlock, removeBlock, moveData, submitCheck }
  },
})
</script>

<style scoped lang="postcss">
.title {
  @apply mb-[8px];
}
.content-block-list {
    @apply flex flex-col gap-[20px] mb-[12px];
}
</style>
